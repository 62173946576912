<template>
    <div class="add-modify-role">
        <el-dialog
            :title="comTitle"
            :visible.sync="show"
            :close-on-click-modal="false"
            width="424px">
            <el-form :model="roleInfo" :rules="rules" ref="roleInfo" label-width="68px" class="data-form role-manage" @submit.native.prevent>
                <el-form-item label="角色名称" prop="roleName">
                    <el-input class="input-w290-h32" v-model="roleInfo.roleName" placeholder="请输入角色名称"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="desc">
                    <el-input type="textarea" v-model="roleInfo.desc"  maxlength="100" :rows="7" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="分配权限" prop="backShowpermisIdList">
                    <div class="permise-tree-form">
                        <el-tree
                            :data="permisList"
                            show-checkbox
                            :default-checked-keys="roleInfo.backShowpermisIdList"
                            ref="tree"
                            node-key="permisId"
                            :props="defaultProps">
                        </el-tree>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn  type="info" @click="show = false"  value="取消"></tem-btn>
                <tem-btn  type="primary" @click="submitData('roleInfo')"  value="确定"></tem-btn>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    model: {
        prop: 'roleDialogVisible',
        event: 'roleEvent'
    },
    props:{
        roleDialogVisible:{
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default:'add'
        },
        roleInfo:{
            type: Object,
            default:() => {
                return {
                    roleName:'',
                    desc:'',
                    permisIdList:[],
                    backShowpermisIdList:[]
                }
            }
        },
        permisList:{
            type: Array,
            default:() => {
                return []
            }
        }
    },
    computed:{
        comTitle(){
            return this.title == 'add' ? '新增角色' : '编辑角色'
        }
    },
    data(){
        return {
            show: this.roleDialogVisible,
            rules:{
                roleName: [{ required: true, message: '请输入角色名称', trigger: 'change' },
                        { required: true, pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,10}$/, message: '中文或数字或字母，长度 1-10 个字符', trigger: 'change' },],
                desc: [
                        { min: 0, max: 100, message: '备注100个字符以内', trigger: 'change' }
                    ]
            },
            defaultProps:{
                children: 'children',
                label: 'permisName'
            }
        }
    },
    watch: {
        //对父组件传来的地址进行监听
        show:{
            handler(newVal, oldVal) {
                if(!newVal) {
                    this.$refs.roleInfo.resetFields();
                }
                this.$emit('roleEvent',newVal);
            },
            deep: true,
        },
        roleDialogVisible:{
            handler(newVal, oldVal) {
                this.show = newVal;
            },
            deep: true,
        },
    },
    methods:{
        submitData(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.roleInfo.permisIdList = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
                    this.$emit('submitData',this.roleInfo);
                }
            });
        },
    }
}
</script>
<style lang="scss">
.add-modify-role { 
    .permise-tree-form {
        height:240px;
        max-height:240px;
        overflow:auto;
        box-sizing:border-box;
        padding:8px 0px;
        background-color:#FFF;
        border-radius: 4px;border: 1px solid #D9D9D9;
    }
}
</style>