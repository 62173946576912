import request from '@/utils/request'

//获取用户列表
export function listPageRole(data) {
    return request({
        url: '/rbac/role/listPageRole',//请求路径
        method: 'get',
        params: data
    });
}
//获取权限列表
export function listTreePermis(data) {
    return request({
        url: '/rbac/permis/listTreePermis ',//请求路径
        method: 'get',
        params: data
    });
}

//新增角色
export function addRoleAndPermis(data) {
    return request({
        url: '/rbac/role/addRoleAndPermis ',//请求路径
        method: 'post',
        data
    });
}
//修改角色信息
export function modifyRolePermis(data) {
    return request({
        url: '/rbac/role/modifyRoleAndPermis',//请求路径
        method: 'post',
        data
    });
}
//查询角色信息
export function getRoleAndPermis(data) {
    return request({
        url: '/rbac/role/getRoleAndPermis',//请求路径
        method: 'get',
        params:data
    });
}
//修改角色状态
export function modifyRole(data) {
    return request({
        url: '/rbac/role/modifyRole',//请求路径
        method: 'post',
        data
    });
}
