<template>
  <div>
    <div class="role-management">
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="listQuery" :model="listQuery" @submit.native.prevent>
                    <div class="form jcl">
                        <div class="search-conditions">   
                            <el-form-item label="角色名称" prop="roleNameLike">
                                <el-input class="input-w290-h32" :maxlength="120" v-model="listQuery.roleNameLike" placeholder="请输入角色名称"></el-input>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item class="clear-margin-bottom" label="" prop="includeSub">
                                <el-checkbox v-model="listQuery.includeSub">包含下级</el-checkbox>
                            </el-form-item>
                            <tem-btn  type="primary" value="查询" @click="getTableList('search')"></tem-btn>
                            <tem-btn  type="info" value="清空" @click="restForm('listQuery')"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="table-list">
            <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize"
            :orderBy.sync="listQuery.orderBy" @pagination="getTableList"></Table>
        </div>
      </el-row>
    </div>
    <addOrModifyRole ref="role" @submitData="submitData" v-model="roleDialogVisible" :permisList="permisList" :roleInfo="roleInfo" :title="title"/>
  </div>
</template>

<script>
  import Table from '@/components/table/table';
  import addOrModifyRole from './addOrModifyRole'
  import {
    listPageRole,
    getRoleAndPermis,
    listTreePermis,
    modifyRolePermis,
    addRoleAndPermis,
    modifyRole
  } from '@/api/role-management/index';
  export default {
    components: {
      Table,
      addOrModifyRole
    },
    data() {
      return {
        //新增编辑角色弹窗
        roleDialogVisible:false,
        //搜索栏数据
        listQuery:{
            roleNameLike:'',//机构名称
            total:10000,//数据条数
            includeSub:false,//是否包含下级
            pageIndex: 1,//当前查询页面
            pageSize: 10,//每页数据数量
            // orderBy:''
        },
        //角色信息
        roleInfo:{
            roleName:'',
            desc:'',
            permisIdList:[],
            backShowpermisIdList:[],
        },
        permisList:[],//所有权限数组
        //表格数据
        table: {
          configItem: true, //是否开启表格项配置
          data: [],
          //表头按钮
          operateBtns:[
              {
                  value:'新增角色',
                  type:'primary',
                  event: () => {
                      this.listTreePermis().then( res => {
                          this.title = 'add';
                          this.roleDialogVisible = true;
                      });
                  }
              }
          ],
          header: [
            {
              type: 'index',
              label: '序号',
              width: '55',
              ifShow: true,
            },
            {
              prop: 'roleName',
              label: '角色名称',
              minWidth: '180',
              ifShow: true,
            },
            {
              prop: 'createrName',
              label: '创建人',
              minWidth: '120',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'createTime',
              label: '创建时间',
              sortable: 'column',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'updaterName',
              label: '更新人',
              minWidth: '120',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'updateTime',
              label: '更新时间',
              sortable: 'column',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'status',
              label: '状态',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
              render:(h,param) => {
                  return h('div', [
                    h(
                        'el-switch', {
                            props: {
                                value: param.status == 1 ? true : false 
                            },
                            on: {
                                change:(val)=> {
                                    param.status == 1 ? param.status = true : param.status = false;
                                    modifyRole({status: !param.status ? 1 : 2,roleId:param.roleId}).then( res => {
                                        if( res.code === 200 ) {
                                            param.status = !param.status;
                                            this.getTableList();
                                            if(param.status) {
                                                this.$message({type:'success',message:'启用成功'});
                                            } else {
                                                this.$message({type:'success',message:'停用成功'});
                                            }
                                            
                                        }
                                    })
                                }
                            }
                        }
                    ),
                ]);
              }
            },
            {
              prop: 'desc',
              label: '备注',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'options',
              label: '操作',
              minWidth: '180',
              ifShow: true,
              render:(h,param) => {
                return h('div', [
                    h(
                        'el-tooltip', {
                            props: {
                                effect:'dark',
                                content:'编辑',
                                placement:'top'
                            }
                        },
                        [h('span',{
                            style:{
                                cursor:'pointer'
                            },
                            class:'svg-hover',
                            on:{
                                click:()=> {
                                    this.title = 'edit';
                                    this.listTreePermis().then( res => {
                                        this.title = 'edit';
                                        this.getRoleAndPermis(param.roleId);
                                        this.roleDialogVisible = true;
                                    });
                                }
                            }
                        },[
                            h('svg-icon',{
                                props:{
                                    iconClass:'icon-edit',
                                    size: '16',
                                    className:' icon'
                                },
                            })
                        ])
                        ]
                    ),
                ]);
              }
            }
          ],
        },
        title:'add'
      }
    },
    mounted() {
        //获取列表数据
        this.getTableList();
    },
    methods: {
        /**
         * 通过角色id获取角色信息
         * @param { Number } roleId 角色id
         */
        getRoleAndPermis(roleId){
            getRoleAndPermis({roleId}).then( res => {
                if( res.code === 200 ) {
                    this.roleInfo =  res.data;
                    this.roleInfo.backShowpermisIdList = [];
                    res.data.permisIdList.forEach((v,i,r) => {
                        let node = this.$refs.role.$refs.tree.getNode(v);
                        if(node.isLeaf){ 
                            this.roleInfo.backShowpermisIdList.push(v)
                        }
                    })
                }
            })
        },
        /**
         * 提交数据
         */
        submitData(roleInfo) {
            if( this.title == 'add') {
                addRoleAndPermis(roleInfo).then( res => {
                    if( res.code === 200 ) {
                        this.getTableList();
                        this.roleDialogVisible = false;
                        this.$message({type:'success',message:'新增角色成功'});
                    }
                })
            } else if( this.title == 'edit' ) {
                modifyRolePermis(roleInfo).then( res => {
                    if( res.code === 200 ) {
                        this.getTableList();
                        this.roleDialogVisible = false;
                        this.$message({type:'success',message:'编辑角色成功'});
                    }
                })
            }
            
        },
        /**
         * 获取权限树形结构
         */
        listTreePermis(){
            return new Promise( (resolve,reject ) => {
                listTreePermis().then( res => {
                    if( res.code === 200 ) {
                        this.permisList = res.data;
                        resolve('success')
                    }
                })
            });
        },
        /**
         * 重置搜索导航栏
         * @param { Object } formName 当前表单的ref值
         */
        restForm(formName){
            this.$refs[formName].resetFields();
        },
        /**
         * 获取表格数据
         */
        getTableList(flag){
            if( flag == 'search' ) {
                this.listQuery.pageIndex = 1;
            }
            listPageRole(this.listQuery).then( res => {
                if( res.code === 200 ) {
                    this.table.data = res.data;
                    this.listQuery.total = res.count;
                }
            })
        },        
    }
  }

</script>
<style lang="scss" scoped>
.role-management {
    //表格样式
}

</style>